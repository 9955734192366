import React from 'react'

const NotFound = () => {
  return (
    <div className='text-center'>
      
      <img className="img-fluid" src="img/404-not-found.webp" alt="404-not-found" />
    </div>
  )
}

export default NotFound 