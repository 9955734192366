import React, { Component } from "react";
import { baseUrl } from "../constant/BaseUrl";

class TradingViewChart extends Component {
  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.tvWidget = null; // Initialize a reference to the TradingView widget
  }

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    const { firstCoin, secondCoin } = this.props;
    if (
      firstCoin != prevProps.firstCoin ||
      secondCoin != prevProps.secondCoin
    ) {
      this.updateSymbol();
    }
  }

  initChart() {
    const { firstCoin, secondCoin } = this.props;
    const pairName = firstCoin + secondCoin;
    // Initialize the TradingView widget with the initial symbol

    this.tvWidget = new window.TradingView.widget({
      fullscreen: false,
      symbol: pairName,
      interval: "1D",
      container: this.chartContainerRef.current,
      height: 355,
      width: "100%",
      //   custom_css_url:"/css/TradingView.css",
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(baseUrl),
      library_path: "/charting_library/",
      locale: "en",

      // disabled_features: ["use_localstorage_for_settings","header_widget"],
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "om",
      user_id: "public_user_id",
      theme: "dark",
      timezone: "Asia/Kolkata",
      overrides: {
        "mainSeriesProperties.style": 8,
      },
    });
    this.tvWidget.onChartReady(() => {
      this.updateSymbol();
    });
  }

  updateSymbol() {
    // Update the symbol property of the existing widget instance
    if (this.tvWidget) {
      const { firstCoin, secondCoin } = this.props;
      const pairName = firstCoin + secondCoin;
      this.tvWidget &&
        this.tvWidget.setSymbol(pairName, "1D", () => {
          console.log("Symbol changed successfully");
        });
    }
  }

  componentWillUnmount() {
    if (this.tvWidget) {
      this.tvWidget.remove();
    }
  }

  render() {
    return <div ref={this.chartContainerRef} id="tv_chart_container"></div>;
  }
}

export default TradingViewChart;
