import {
  changeStatusAuth,
  googleCodeGenerate,
  loginVerify2fa,
  userInfo,
  submitKyc,
  userActivity,
  resetPassword,
  getCryptoPortfolio,
  widgetPermissions,
} from "../components/constant/Api";
import { myApi } from "./api";

export const UserApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWidgets: builder.query({
      query: ({ type }) => ({
        url: `${widgetPermissions}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getallUser: builder.query({
      query: () => ({
        url: userInfo,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getUserActivity: builder.query({
      query: () => ({
        url: userActivity,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    generateGoogleCode: builder.query({
      query: () => ({
        url: googleCodeGenerate,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getCryptoPortfolio: builder.query({
      query: () => ({
        url: getCryptoPortfolio,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    changeStatus2Fa: builder.mutation({
      query: (post) => ({
        url: changeStatusAuth,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    verifyLoginCode: builder.mutation({
      query: (post) => ({
        url: loginVerify2fa,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    submitKyc: builder.mutation({
      query: (post) => ({
        url: submitKyc,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    changePassword: builder.mutation({
      query: (post) => ({
        url: resetPassword,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
  }),
});
export const {
  useGetallUserQuery,
  useGenerateGoogleCodeQuery,
  useChangeStatus2FaMutation,
  useVerifyLoginCodeMutation,
  useSubmitKycMutation,
  useGetUserActivityQuery,
  useChangePasswordMutation,
  useGetCryptoPortfolioQuery,
  useGetUserWidgetsQuery,
} = UserApi;
