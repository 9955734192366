import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PermissionNot = () => {
  return (
    <div>
      <Navbar />
      <section className="p70  pt-md-5 pb-md-5 text-center">
        <div className="container">
        <div className="row mb-2">
        <div className="col-md-3 m-auto">
        <img src="/img/pp.png" className="img-fluid" />
        
      </div>
      </div>
      <h5>Sorry You Are Not Allowed to Access This Page</h5>
      </div>
      </section>
      <Footer />
    </div>
  );
};

export default PermissionNot;
